img.icon, img.icon-action {
    width:1rem;     
}

img.icon-action {
    cursor:pointer;
}
img.big{
    width:2rem;
}

img.flag{
    width:40px;    
}

img.quote{
    width:2rem;
}

i.yellow{
    color:#ffd700;
}

i.orange{
    color:#FFA500;
}

i.blue{
color:#0080ff;
}

i.red{
color:#ff3d30;
}

i.green{
    color:#33ff33;
    }

i.gold{
    color:rgba(255, 217, 0, 0.897)
}
    